import { get, isString } from 'lodash-es'

type Handler = (text: string, evt: ClipboardEvent) => void

export function usePasteClipboard() {
  const clipboardText = ref('')

  const hasClipboardReadPermission = usePermission('clipboard-read')

  function handlePaste(handler: Handler) {
    return function pasteHandler(evt: ClipboardEvent) {
      evt && evt.preventDefault()
      readClipboard(evt).then((text) => {
        if (text && isString(text)) {
          handler(text, evt)
        }
      })
    }
  }

  async function readClipboard(evt: ClipboardEvent) {
    return hasClipboardReadPermission.value === 'granted'
      ? await readNavigatorClipboard()
      : readEventClipboard(evt)
  }

  function readEventClipboard(evt: ClipboardEvent) {
    const clipboardData =
      evt.clipboardData ||
      (get(window, 'clipboardData') as unknown as DataTransfer)

    if (clipboardData) {
      clipboardText.value = clipboardData.getData('text')
      return clipboardText.value
    } else {
      console.error('Clipboard API is not supported in this browser.')
    }
  }

  async function readNavigatorClipboard() {
    try {
      clipboardText.value = await navigator.clipboard.readText()
      return clipboardText.value
    } catch (error) {
      console.error('Failed to read from navigator clipboard:', error)
    }
  }

  return {
    handlePaste,
    clipboardText,
  }
}
